import React, { useContext, useMemo } from 'react'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import useLocation from 'react-use/lib/useLocation'
import parseISO from 'date-fns/parseISO'
import useUser from './useUser'

export const AxiosContext = React.createContext(axios?.create?.())
AxiosContext.displayName = 'AxiosContext'

type UseAxiosParams = {
  withPartnerAuth: boolean
}

interface RetryConfig extends AxiosRequestConfig {
  retry: number
  retryDelay: number
}

export const defaultRetryConfig: RetryConfig = {
  retry: 3,
  retryDelay: 1000,
}

export const sleep = (ms: number): Promise<unknown> =>
  new Promise((resolve) => setTimeout(resolve, ms))

const addSuccessAndRetryResInterceptors = (
  axiosInstances: AxiosInstance[]
): void => {
  axiosInstances.forEach((axiosInstance) =>
    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const { config } = error

        if (!config || !config.retry) {
          return Promise.reject(error)
        }

        config.retry -= 1

        await sleep(config.retryDelay || 1000)

        return await axiosInstance(config)
      }
    )
  )
}

/**
 * useAxios is a React hook that provides a single Axios instance that is usable
 * by all functionality that needs to make ajax calls.
 */
export default function useAxios(
  { withPartnerAuth }: Partial<UseAxiosParams> = { withPartnerAuth: false }
): AxiosInstance {
  const axiosInstance = useContext(AxiosContext)
  const { pt, st } = useUser()

  if (!axiosInstance) {
    throw new Error('useAxios can only be used within the AxiosProvider!')
  }

  const axiosInstanceWithAuth = useMemo(
    () =>
      axios.create({
        ...axiosInstance.defaults,
        auth: {
          username: atob(pt ?? ''),
          password: atob(st ?? ''),
        },
      }),
    [axiosInstance.defaults, pt, st]
  )

  addSuccessAndRetryResInterceptors([axiosInstance, axiosInstanceWithAuth])

  if (withPartnerAuth) {
    return axiosInstanceWithAuth
  }

  return axiosInstance
}

const dateFormat = Object.freeze(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/)

export const AxiosProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // I think, for some reason, Axios is setting a baseURL when Gatsby does
  // server side rendering (and setting it to the production URL?). This should
  // allow the Axios instance to update upon first render.
  const { origin } = useLocation()
  const axiosInstance = useMemo(
    () =>
      axios.create({
        baseURL: origin,
        transformResponse: (data, headers) => {
          // Handle empty responses
          if (!data) {
            return data
          }

          // We don't want to transform anything other than json
          if (!headers?.['content-type']?.includes('application/json')) {
            return data
          }

          // Transform datetime strings into Javascript Date objects.
          return JSON.parse(data, (_, value) => {
            if (typeof value === 'string' && dateFormat.test(value)) {
              return parseISO(value)
            }

            return value
          })
        },
      }),
    [origin]
  )

  return (
    <AxiosContext.Provider value={axiosInstance}>
      {children}
    </AxiosContext.Provider>
  )
}
