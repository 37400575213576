import React, { useContext } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import XRayContext from '../Providers/XRayContext'
import Box from '@material-ui/core/Box'

interface Props {
  id: string
  children: React.ReactNode
}

const XRayWrapper: React.FC<Props> = ({ id, children }) => {
  const classes = useStyles()
  const componentContext = useContext(XRayContext)

  const clickEvent = (contentfulId: string) => {
    componentContext.setXRayId(contentfulId)
  }

  if (componentContext?.xRay) {
    return (
      <Box
        className={clsx({
          [classes.box]: componentContext.id !== id,
          [classes.boxClicked]: componentContext.id === id,
        })}
        onClick={() => clickEvent(id)}
      >
        {children}
      </Box>
    )
  } else {
    return <React.Fragment>{children}</React.Fragment>
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    box: {
      '&:hover': {
        border: '3px solid pink',
      },
    },
    boxClicked: {
      border: '3px solid orange',
    },
  })
)

export default XRayWrapper
