import useAxios, { sleep } from '@talentinc/gatsby-theme-ecom/hooks/useAxios'
import React, { useCallback, useEffect, useMemo } from 'react'
import { UseQueryResult, useQuery } from 'react-query/react'
import useLocation from 'react-use/lib/useLocation'
import { Endpoints, Timeouts, ZipJobConstants } from '../../types/constants'
import { isBrowser } from '../../utils/env'
import useLeadActions from '../../hooks/useLeadActions'
import { QueryObserverResult } from 'react-query'

type PartnerCoBrandType = {
  name: string
  logo: string
  logoInverse: string
}

type UtmParameters = {
  campaign: string
  medium: string
  source: string
}

export interface PartnerDetails {
  partnerCoBrand: PartnerCoBrandType
  partnerId: number
  partnerName: string
}

export interface SessionAPIResponse extends PartnerDetails {
  lead_auth_token: string | null
  email: string | null
  uploaded_resume: boolean
  ordered_resume_checksums: string[]
  viewable_critique: boolean
  pt: string
  st: string
  hashedEmail: string
  userLeadAge: number
  userLeadScore: number
  leadId: number
  utmParameters: UtmParameters
  sessionID: string
}

export interface CirtiqueStateResponse {
  critique_emailed_at: string | null
  critique_expired_at: string | null
  critique_viewed_at: string | null
  critique_persona: string | null
  first_name: string | null
  last_name: string | null
  purchased_resume_at: string | null
  resume_status: number | null
}

export interface UserSessionProviderValue extends Partial<SessionAPIResponse> {
  isLoading: boolean
  isError: boolean
  refetchSession: () => Promise<
    QueryObserverResult<SessionAPIResponse, unknown>
  >
}

const defaultUserSessionProviderValue = Object.freeze({
  lead_auth_token: null,
  email: null,
  uploaded_resume: false,
  ordered_resume_checksums: [],
  viewable_critique: false,
  pt: '',
  st: '',
  hashedEmail: '',
  userLeadAge: 0,
  userLeadScore: 0,
  partnerId: 15,
  partnerCoBrand: {
    name: '',
    logo: '',
    logoInverse: '',
  },
  isLoading: false,
  isError: false,
  refetchSession: () =>
    new Promise(() => {
      /* Do nothing */
    }),
} satisfies UserSessionProviderValue)

export const UserSessionContext = React.createContext<UserSessionProviderValue>(
  defaultUserSessionProviderValue
)

const UserSessionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { search, hostname } = useLocation()
  const params = useMemo(
    () => new URLSearchParams(search?.substring(1) ?? ''),
    [search]
  )
  const isZipJob = hostname?.includes('zipjob.com')

  const { data: apiData, isLoading, isError, refetch } = useSessionQuery()

  const refetchSession = useCallback(async () => {
    await sleep(Timeouts.RefetchSession)
    return refetch()
  }, [refetch])

  const data: UserSessionProviderValue = useMemo(
    () => ({
      ...apiData,
      isLoading,
      isError,
      refetchSession,
    }),
    [isLoading, isError, apiData, refetchSession]
  )

  useEffect(() => {
    // Store the `zj` Impact token to the Session Storage
    if (isZipJob) {
      const zjImpactPartnerToken = params.get(ZipJobConstants.ImpactPartnerKey)

      if (zjImpactPartnerToken && isBrowser) {
        sessionStorage.setItem(
          ZipJobConstants.ImpactPartnerKey,
          zjImpactPartnerToken
        )
      }
    }
  }, [params, isZipJob])

  return (
    <UserSessionContext.Provider value={data}>
      {children}
    </UserSessionContext.Provider>
  )
}

export const useSessionQuery = (): UseQueryResult<
  SessionAPIResponse,
  unknown
> => {
  const axios = useAxios()
  const { search } = useLocation()
  const params = useMemo(
    () => new URLSearchParams(search?.substring(1) ?? ''),
    [search]
  )
  const {
    state: { checksum },
  } = useLeadActions()
  return useQuery<SessionAPIResponse>(
    ['userSession', search ?? '', checksum],
    async () => {
      const url = Endpoints.Session

      if (document.referrer) {
        params.set('rf', document.referrer)
      }

      if (checksum) {
        params.set('cs', checksum)
      }

      const { data } = await axios.get(url, { params })

      return data
    },
    { staleTime: 0 }
  )
}

export const useCritiqueStateQuery = (): UseQueryResult<
  CirtiqueStateResponse,
  unknown
> => {
  const axios = useAxios()
  const { search } = useLocation()
  const params = useMemo(
    () => new URLSearchParams(search?.substring(1) ?? ''),
    [search]
  )
  const {
    state: { checksum },
  } = useLeadActions()
  return useQuery<CirtiqueStateResponse>(
    ['critiqueState', search ?? '', checksum],
    async () => {
      const url = Endpoints.CritiqueState
      const { data } = await axios.get(url, { params })

      return data
    },
    { staleTime: 0 }
  )
}

export default UserSessionProvider
