// @ts-ignore
export const isBrowser = typeof window !== 'undefined'

// @ts-ignore
export const isSSR = typeof window === 'undefined'

export const isProduction =
  isBrowser &&
  !['perf', 'dev', 'preview', 'staging', 'localhost'].find((env) =>
    // @ts-ignore
    window?.location?.host.includes(env)
  )

export const isDevelopment = process.env.NODE_ENV === 'development'
